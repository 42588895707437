.wr_rate {
display: flex;
}
.d_rate {
display: table;
background: #1D2C30;
border: 1px #394c52 solid;
border-radius: 5px;
}
.d_rate .tr {
display: table-row; 
font-size: 1.7vh;
}
.d_rate .td {
display: table-cell;
padding: 5px 15px;
vertical-align: middle;
color: #c7c8ca;
}

.d_rate .td .fle {
display: flex;
align-items: center;
color: #c7c8ca;
}
.d_rate .td img{
width: 30px;
padding-right: 10px;
}
.d_rate .l {
border-right: 1px #394c52 solid;
}
.d_rate .r {
text-align: left;
}
.btn_rate {

}