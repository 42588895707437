.dv {
width: 100%;
padding: 35px 20px 55px 20px;
}



/*
.menul1-enter {
opacity: 0;
}
.menul1-enter-active {
opacity: 1;
transition: opacity 200ms;
}
.menul1-exit {
opacity: 1;
}
.menul1-exit-active {
opacity: 0;
transition: opacity 200ms;
}*/