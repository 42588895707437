* {
margin: 0;
padding: 0;
box-sizing: border-box;
color:#fff;
letter-spacing: 1px;
}

body {
background-color: #152124;
background-image: url("../../public/img/basic/background5.webp");
background-repeat: no-repeat;
background-position: center;
background-size: 80%;
}

.b10 {
bottom: 10px;
}
/* margin */
.mt8 {
margin-top: 8px;
}
.ml15 {
margin-left:7px;
}
/* p */
.p1 {
padding-top:5px;
}

.tal {
text-align: left;
}
.tar {
text-align: right;
}
.tac {
text-align: center;
}

/* Tranc Ani */
.upS-entering, .upS-entered {
opacity:1;
top:0;
}
.upS-exiting, .upS-exited {
opacity:0;
top:-80%;
}

.lg1-entering, .lg1-entered {
opacity:1;
}
.lg1-exiting, .lg1-exited {
opacity:0;
margin-left: -50px !important;
}

.tr_Li-entering, .tr_Li-entered {
opacity:1;
}
.tr_Li-exiting, .tr_Li-exited {
opacity:0;
}

.br {
border-radius:5px;
}

.divLiCo {
width: 80%;
border: 2px #376773 solid;
margin: 0 auto;
margin-top: -15px;
}

.w1m0 {
width: 100% !important;
margin: 0 !important;
}

.bg102 {
background: #3e595f;
}

/* Z-index */
.z5 {
z-index: 5;
}
.z6 {
z-index: 6;
}
.z7 {
z-index: 7;
}

.bgB {
position: fixed;
width: 100%;
height: 100%;
background: #091b1de8;
z-index: 1001;
}

/* Heights */
.h100 {
height:100%;
}
.whF {
width:100%;
height:100%;
}
.whA {
height: 100%;
width: auto;
}
.cn {
cursor: not-allowed;
}
.wd1 {
width:100%;
}
.lt0 {
left:0;
top:0;
}
/* Colors */
.co1 {
color: #79bbaa;
}
.co2 {
color: #abfae2;
}
.co3 {
color: #5b9a8f;
}
.co4 {
color: #5d8f84;
}
.co5 {
color: #ff8c8c;
}
.co6 {
color:#81b4bf;
}
/* Paddings */
.pad15 {
padding:15px;
}
.pad5 {
padding:5px;
}

/* Position */
.pa {
position: absolute;
}
.pf {
position: fixed;
}
.pr {
position: relative;
}
/* Central */
.cl {
left:50%;
transform:translate(-50%, 0);
}
.cc {
left:50%;
top:50%;
transform:translate(-50%, -50%);
}
.ct {
top: 50%;
transform: translate(0, -50%);
}

.win_noti {
border: 1px #67818a solid;
padding: 15px;
min-width: 30%;
}

/* Font sizes */
.fs01 {
font-size:10px;
}
.fs1 {
font-size:12px;
}
.fs2{
font-size: 14px;
}
.fs3{
font-size: 16px;
}
.fs4 {
font-size: 18px;
}
.fs5 {
font-size: 24px;
}
.fs6 {
font-size: 30px;
}


/* BTNs */
.btnx1 {
right: 20px;
position: absolute;
top: 20px;
color:#fff;
}
.btnx1:hover {
color:#cb899a;
}
.btn1 {
padding: 10px 5px 10px 20px;
width: 100%;
display: flex;
align-items: center;
border-radius:5px;
margin: 15px 0;
position: relative;
z-index: 102;
}
.btnL {
width: calc(100% - 45px);
text-align: left;
}
.btnR {
width: 45px;
text-align: right;
display: flex;
align-items: center;
}
.btn1 .c {
border-radius: 10px;
background: #626a6c;
width: 15px;
height: 15px;
}
.actCol {
background: #a6e1f0 !important;
}
.btn1 .ar_R {
color:#fff;
font-size: 20px;
margin-left: 10px;
}
.n {
font-size: 15px;
color: #539DAE;
}
.bp {
font-size: 12px;
color: #cecece;
padding: 3px 0 0px 10px;
}
.hbtn1 {
background: #283E43;
}
.hbtn1:hover {
background: #3a6670;
}
.hbtn1:hover .r .c {
background: #68b3c4;
}
.hbtn1:hover .r .ar_R{
color:hsl(191, 44%, 59%);
}
.btn6s5 {
padding: 20px;
border-radius: 35px;
z-index: 100;
background: #283946;
}
.hbtn2 {
background: #366b57;
}
.hbtn2:hover, .btn6s5:hover {
background: #54a889;
}
.hbtn2:hover .r .c {
background: #68b3c4;
}
.hbtn2:hover .r .ar_R{
color:#68b3c4;
}
.hov5 {
position: fixed;
cursor: default;
z-index: 0;
}
.btn2 {
padding: 5px 30px;
border: 1px #56737a solid;
border-radius: 10px;
margin-left: 30px;
color: #668992;
}
.btnNavUp {
margin: 0 10px;
font-family: 'Play', sans-serif;
letter-spacing: 2px;
text-decoration:none;
}
.bt7cj {
color:#fff;
}
.bt5hact {
color: #40ff9d;
text-shadow: 0 0 25px #73fe89, 0 0 20px #e6ff5d, 0 0 5px #719fa9;
}
.nab6h {
color: #9b9b9b;
}
.bt7cj:hover {
color: #fff700;
text-shadow: 0 0 25px #85f185, 0 0 20px #5dff7c, 0 0 5px #719fa9;
}

.btnAp {
color: #fff;
background: #27603f;
padding: 10px 20px;
width: 80%;
border-radius: 5px;
text-align: left;
}

.btnCho {
border: 1px #588d7f solid;
width: 100%;
margin: 10px 0;
}
.btnCho:hover {
background:#315859;
}
.btnChoN {
border: 1px #588d7f solid;
width: 100%;
margin: 10px 0;
background: #324347;
cursor:unset;
}
.btno1 {
display: block;
padding: 10px;
width: 100%;
text-align: right;
font-size: 15px;
}
.btno1:hover {
color: #53c5c5;
}
.btno1Act {
background: #355f6b;
}
.btno2 {
background: #318383;
margin: 0 auto;
padding: 10px 20px;
font-size: 14px;
margin-top: 25px;
text-align: center;
border-radius: 5px;
}
.btno2:hover {
background: #42a7a7;
}
.i6l4 {
color: #a6f0d5;
}
.bto:hover .i6l4 {
color: #e9df86;
}

/* BTN Card */
.btncoll {
position: relative;
width: 100%;
border: 1px #3e595f solid;
display: block;
margin: 15px auto;
}
.btncoll:hover {
background: #264045;
}
.onListbtn {
position: absolute;
right: 10px;
top: 50%;
transform: translate(0, -50%);
}

/* ScrollBar */
* {
scrollbar-width: thin;
scrollbar-color: #3b443a #343331;
}        
*::-webkit-scrollbar {
width: 5px;
}        
*::-webkit-scrollbar-track {
background: #000000;
}        
*::-webkit-scrollbar-thumb {
background-color: #589fab;
border-radius: 20px;
border: 1px solid #2a6368;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,300&display=swap');

@font-face {
font-family: 'Play';
src: url('../fonts/Play/Play-Regular.ttf');
}


button {
background: none;
color:#fff;
border: 0;
cursor: pointer;
}
#root {
width:100%;
height:100%;
}
img {
width:100%;
}
h1 {
color:#fff;
font-size:3vh;
}
.h11_l {
width: 100%;
height: 2px;
background: #37565d;
}
.h11 {
padding:15px 15px 15px 0;
font-family: 'Play', sans-serif;
letter-spacing: 3px;
}
.h2 {
color: #a5fcda;
border-bottom: 4px #283e43 solid;
padding: 10px 15px 5px 15px;
}

.h3 {
text-align: center;
padding: 5px 10px 15px 10px;
font-size: 20px;
color: #e1e1e1;
}
.h31 {
color: #b1edd5;
font-size: 20px;
border-bottom: 1px #727d7b solid;
margin-bottom: 10px;
}

/* Loaders */
.lds-ring {
z-index: 99;
position: absolute;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
width: 80px;
height: 80px;
}
.lds-ring div {
box-sizing: border-box;
display: block;
position: absolute;
width: 64px;
height: 64px;
margin: 8px;
border: 8px solid #528a97;
border-radius: 50%;
animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
border-color: #528a97 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
 
/* Display */
.df {
display: flex;
align-items: center;
}
.jcr {
justify-content: right;
}
.jcc {
justify-content: center;
}
.jcl {
justify-content: left;
}
/*  ---------------- */

html,body {
width:100%;
/*overflow:hidden;*/
height:100%;
}
body {
background-color: #152124;
}

/* widths */
.w100 {
width:100% !important;
}
.wi8 {
width:80%;
}
.wi5 {
width:50%;
}

/* Paddings */
.pa1 {
padding: 10px 5px 10px 20px;
}

/* Margins */
.ma1 {
margin: 15px 0;
}

/* Animate */
.ani1{
-webkit-transition: all 0.4s ease-in-out;
-moz-transition: all 0.4s ease-in-out;
-o-transition: all 0.4s ease-in-out;
-ms-transition: all 0.4s ease-in-out;
transition: all 0.4s ease-in-out;
}
.sh1 {
animation: 0.5s show1 ease;
}

@keyframes show1 {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* ---------------*/
.wr1 {
max-width:1924px;
width:100%;
margin: 0 auto;
padding: 5px 20px;
height: 100%;
}
.rel {
margin-left: 10px;
}
.faRe {
color: #9bb3c2;
}
.rel:hover .faRe {
color: #0dff00;
}
/* HEADER */
.header {
width: 100%;
height: 70px;
display: flex;
flex-direction: row;
justify-content: space-between;
flex-wrap: wrap;
z-index: 1001;
}
.rap3 {
  width:100vh;
  height:100vh;
  position:absolute;
  top:0;
  left:0;
  background-color: #000;
  z-index: 9998;
  display:none;
}
.gg6 {

}
.logo {
height:70px;
padding: 15px 10px 15px 0;
}
.logo img {
height:100%;
}
nav {
padding-left: 20px;
}
nav a{

}
.app {
color:#fff;
}

/* Div chLang Full  */
.langFdiv {
position: absolute;
background: #23393e;
padding: 5px;
margin-top: 10px;
margin-left: -150px;
z-index: 1003;
}
.btnLang {
width: 100%;
padding: 10px;
}
.btnLang:hover {
background: #749b90;
}
.actLang {
background: #467467;
color: #d4e835;
font-weight:700;
}

/* Pages */
.page1 {
width: 100%;
height: calc(100% - 70px);
display: flex;
flex-direction: row;
}
.page12 {
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: row;
  }
  .page13 {
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: row;
    }


/* Bars */
.bar1 {
width: 250px;
position: relative;
}
.bar2 {
width:calc(100% - 250px);
overflow-y: auto;
padding:20px;
}
.bar22 {
width:calc(100% - 250px);
overflow-y: auto;
padding:20px;
}
.bar23 {
width:calc(100% - 250px);
overflow-y: auto;
padding:20px;
}

/*--------- UI ----------/*
/* btn */



.lm_rates {
padding-top: 20px;
border-top: 2px #37565d solid;
margin-top: 20px;
z-index: 102;
position:relative;
}

.v7k4 {
padding: 5px 15px;
background: #1D2C30;
border: 1px #394c52 solid;
border-radius: 5px;
margin-bottom: 15px;
font-size: 1.8vh;
color: #6c6c6c;
}
.fa-chevron-down {
float: right;
color: #6c6c6c;
}

/* Lines */
.line1 {
width: 1px;
height: 60%;
border: 1px #455C62 dashed;
bottom: 0;
position: absolute;
left: 50%;
}

/* Market Body */
.ditemNft {
width: calc(33% - 20px);
margin: 10px;
display: inline-block;
}
.dNftNft {
width: calc(100% - 20px);
margin: 10px;
margin-bottom: 15px;
max-height: 230px;
height: 230px;
background: #1c2b2f;
padding: 15px;
border-bottom: 1px #42717c solid;
border-top: 1px #42717c solid;
}
.dNftNft:hover,.dNftNft2:hover { 
background:#1d373f;
}
.iNftNft {
background: #1c2b2f;
border: 1px #3d5258 solid;
border-radius: 5px;
width: 30%;
height: 100%;
padding: 10px;
}
.arrow1 {
width: 30px;
text-align: center;
margin: 15px;
}
.pl_nft2 {
position:relative;
width:30%;
max-height:30vh;
}
.pl_nft2:after {
content: "";
padding-top: 70%;
display: block;
} 
.itemNft {
background: #1c2b2f;
border-radius: 5px;
width: 100%;
padding: 10px;
position: relative;
}
.pl_nft {
position:relative;
width:100%;
height: 100%;
}
.pl_nft:after {
content: "";
padding-top: 70%;
display: block;
} 
.pl_nft1 {
position:relative;
width:100%;
height: calc(100% - 20px);
}
.pl_nft1:after {
content: "";
padding-top: 70%;
display: block;
} 
.itemNft:hover, .iNftNft:hover, .btnDoff:hover {
-webkit-box-shadow: 0 0px 5px rgb(58 102 112), 0 0 10px rgb(97 159 173), 0 0 15px rgb(64 116 129);
-moz-box-shadow: 0 0px 5px rgb(58 102 112), 0 0 10px rgb(97 159 173), 0 0 15px rgb(64 116 129);
-o-box-shadow: 0 0px 5px rgb(58 102 112), 0 0 10px rgb(97 159 173), 0 0 15px rgb(64 116 129);
-ms-box-shadow: 0 0px 5px rgb(58 102 112), 0 0 10px rgb(97 159 173), 0 0 15px rgb(64 116 129);
box-shadow: 0 0px 5px rgb(58 102 112), 0 0 10px rgb(97 159 173), 0 0 15px rgb(64 116 129);
}
.itemNft .d {
border-top: 1px #455C62 solid;
margin-top: 5px;
padding-top: 10px;
position: relative;
}
.itemNft .d .n {
color: #898989;
font-size: 2vh;
letter-spacing: 2px;
font-style: italic;
font-weight: 600;
}
.it_r {
color: #b4b7b8;
font-size: 11px;
font-weight: 300;
height: 15px;
line-height: 15px;
margin-top: 5px;
}
.itemNft .d img {
width:30px;
margin-left: 5px;
}
.itemNft .r {
text-align: left;
}
.itemNft .d h3 {
font-size: 1.7vh;
padding-bottom: 7px;
font-weight: 200;
color: #bac1c2;
}
.itemNft .l {
text-align: right;
}
.itemNft .c {
position: absolute;
top: 0;
left: 50%;
transform: translate(-50%, 0);
width: 100%;
height: 100%;
padding-top: 4px;
}
.price1 {
color: #FF9900;
font-size: 2.4vh;
font-weight: 600;
position: relative;   
}
.tooltip {
position:absolute;
opacity:0;
top:0;
left:0;
z-index:100;
background:#38394d;
padding:5px;
}
.price1:hover .tooltip {
opacity:1;
}
.sp1 {
font-size: 1.8vh;
color: #cdcdcd;
}
.price2 {
font-size: 1.7vh;
color: #b4b7b8;
font-weight: 400;  
}
.price3 {
font-size: 2vh;
color: #9b968f;
font-weight: 300;    
}
.imgNftL {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: auto;
max-width: 100%;
max-height: 100%;
}
.op1-entering {
opacity: 0;
}
.op1-entered {
opacity: 1;
}
.op1-exiting {
opacity: 1;
}
.op1-exited {
opacity: 0;
}

.op0 {
opacity: 0;
z-index: 1 !important;
}
/* colors */

/* Modal */
.modalNft {
position:fixed;
top: 0;
left:0;
z-index: 2000;
width:100%;
height:100%;
background: #0a0c0dc2;
}


/* Exit */
.close {
top: 10px;
color: #ffd9d9;
}
.closeX {
font-size:18px;
color: #cfb69f;
font-weight: 600;
padding-left: 6px;
margin-top: 1px;
}
.close:hover, .close:hover .closeX {
color: #ff6637;
}

.btnser {
padding: 10px 25px;
margin: 0px 5px;
}

.btnC1 {
background: #c27f00;
color: #ffffff;
border: 1px #c27f00 solid;
}
.btnC1:hover {
background: #edaf39;
}
.btnC2 {
background: #992d2d;
color: #fff;
}
.btnC2:hover {
background: #d05050;
}
.btnC3 {
background: none;
color: #98ecd6;
border: 1px #588d7f solid;
}
.btnC3:hover {
background: #588d7f;
color: #fff;
}
.btnC4 {
background: #378164;
color: #fff;
}
.btnC4:hover {
background: #5bc79d;
color: #000;
}

.div1 {
width: 40%;
border: 1px #588d7f solid;
}
.ch_arrow {
width:50px;
text-align: center;
}
.choseDiv {
background: #122423;
left:0;
}
.upD1 {
height:calc(100% - 70px);
overflow-y: auto;
}
.downD1 {
height:70px;
}


/* Add Offer */
.box1 {
width: 80%;
height: 90%;
background: #232a2ddb;
padding: 20px;
}
.r_bxof {
height: calc(100% - 125px);
}
.add_ch {
max-width: 280px;
width: 20%;
border-right: 3px #355f6b solid;
}
.add_tab {
width: 80%;
height:100%;
padding-top: 50px;
text-align: center;
}
.add_txar {
width: 90%;
height: 200px;
margin: 0 auto;
display: block;
background: none;
resize: none;
color: #fff;
padding: 20px;
border-radius: 10px;
border: 3px #437583 solid;
}
.add_warr {
margin: 0 auto;
border-bottom: 2px #355f6b solid;
padding: 10px 20px;
margin-top: 25px;
color: #fff;
font-weight: 200;
min-height: 120px;
}
.coins_v {
margin-top: 7px;
}
.coins_v .uul {
padding-right: 20px;
}
.coins_v .uul img {
width: 30px;
}
.coins_v .uul span {
margin-left: 5px;
color: #8ef071;
font-weight: 400;
font-size: 12px;
}
.add_warr .lt {
width: 80px;
margin-right: 20px;
}
.only_X {
color: #50bf6d;
font-weight: 700;
}



/* Drag and drop */
.drdr{
flex-flow: wrap;
width: 90%;
border:3px #437583 dashed;
margin: 0 auto;
padding: 30px 25px;
text-align: center;
height: 250px;
cursor: pointer;
flex-direction: column;
}
.drdr i {
padding-bottom: 12px;
color: #909090;
}
.drdr span {
display: block;
font-weight: 200;
color: #909090;
}
.drdr:hover {
border-color: #fff;
background: #364448;
}
.drdr:hover i,.drdr:hover span {
color:#fff;
}
.drdrA {
background: #364448;
}
.drdrA i, .drdrA span{
color: #fff;
}
.off_mes {
background: #2c393de0;
z-index: 1002;
}
.hNf {
transform: translate(0,-100%);
border: 1px #588d7f solid;
background: #588d7f;
}
.forimg1 {
width: 33px;
margin-right: 10px;
}
.atrSelD {
display: flex;
border: 1px #3e595f solid;
margin-bottom: 15px;
}
.l12 {
width: 40%;
border-right: 1px #3e595f solid;
display: flex;
justify-content: center;
align-items: center;
}
.r12 {
width:60%;
}
.btnAtr5 {
width: auto;
padding: 10px;
border: 1px #37565d solid;
color: #cbe6ec;
margin: 5px;
}
.bA5act {
background: #37565d !important;
}
.btnAtr5:hover {
background: #2d3a3d;
}

/* listwayOffAdd */
.DoffAct {
width: 100px;
margin: 0 25px;
order: 999;
margin-left: auto;
}
.btnDoff {
margin: 2px auto;
padding:5px;
width:100%;
border: 1px #283e43 solid;
}
.btnDoff:hover {
margin: 2px auto;
padding:5px;
width:100%;
border: 1px #283e43 solid;
}
.inp_o0 {
opacity: 0;
position: absolute;
z-index: -9999;
left: 0;
}

/* About NFT  */
.n1Di {
width: 90%;
height: 95%;
border: 1px #9f9f9f solid;
background: #1c2f38e3;
display: flex;
}
.nInf {
width: 60%;
overflow-y: auto;
border-right: 1px #9f9f9f solid;
padding-right: 10px;
}
.nOff {
width: 40%;
padding: 25px 0 0 15px;
}
.finav {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px #85888a solid;
background: #243740;
}
.nSel {
padding: 5px 20px;
}
.nSel:hover {
background: #63787d;
}
.nSelAct {
background: #9dbdc6;
color: #000;
}
.btn142 {
border: 1px #85888a solid;
background: #243740c4;
position: relative;
}
.btn142:hover {
background:#5c8282;
}
.Dview {
display: flex;
flex-direction: column;
border: 1px #9f9f9f solid;
width: 100%;
height: calc(100% - 140px);
}
.l1d {
background: none;
z-index: 300;
}
.dimg3d {
z-index: 350;
background: #1b2c34;
padding: 25px 10px 10px 10px;
}
.img3d {
max-width: 100%;
max-height: 100%;
width: auto;
}
.loadBar  {
margin-top: 60px;
}
.prBar {
background: #7b8b97;
height: 8px;
}

.canv {
width:100%;
height:100%;
}
.abN {
margin-top: 15px;
}
.icColl {
width: 30%;
}
.pColl {
padding: 20px;
width: 70%;
}
.d63i {
border: 1px #a1c6cc solid;
padding: 5px 10px;
display: inline-block;
margin: 5px;
}
.spN {
display: block;
margin-bottom: 5px;
border-bottom: 1px #fff dashed;
color: #a1c6cc;
}
.navNft {
border-bottom: 1px #9f9f9f solid;
height: 40px;
}
.btn91 {
padding: 5px 20px;
border-left: 1px #73b89d solid;
height: 100%;
}
.btn91:hover {
background: #69a38c;
}
.ac91 {
background: #9ae9ca;
color: #000;
}
.dRnft {
height: calc(100% - 40px);
overflow-y: auto;
}
.s84 {

}
.a52 {
padding: 0 10px;
border-left: 2px #9f9f9f solid;
}
.a52:hover i {
color:#33ffb1;
}
.dj74 {
padding:5px 20px;
}
.dNftNft2 {
height: auto;
margin: 0;
padding: 0;
width: 100%;
background: #1c2b2f;
border-bottom: 1px #42717c solid;
border-top: 1px #42717c solid;
}
.dNftNft2:hover {
background: #1c2b2f;
}
.DoffAct2 {
border-top: 1px #42717c solid;
}
.iNftNft2 {
margin: 5px;
width: 50%;
height: 100%;
padding: 10px;
}
.dt954 {
background: #1c2b2f;
border-bottom: 1px #42717c solid;
border-top: 1px #42717c solid;
margin-top: 10px;
}
.dt954:hover {
background: #334e56;
}
.maNft {
margin: 5px 0;
padding: 5px 0;
border-top: 1px #457e68 solid;
border-bottom: 1px #457e68 solid;
}
.maCo {
margin: 5px 0;
padding: 5px 0;
border: 1px #457e68 solid;
}
.h274 {
margin-top: 10px;
border-bottom: 1px #2a4551 solid;
font-weight: 500;
}
.vr4g {
height: calc(100% - 40px);
overflow-y: auto;
padding-bottom: 35px;
}
.wv3 {
width:30px;
}
.d4g {
background:#213038;
z-index:1500;
}
.h2n8 {
border-bottom: 1px #79bbaa solid;
margin-bottom: 25px;
padding-bottom: 8px;
}
.btn3nf {
border: 1px #79bbaa solid;
}
.btn3nf:hover {
background: #3e5c6b;
}
.btn8h3 {
display: block;
margin: 25px auto;
border: 1px #8bc1dd solid;
padding: 10px 25px;
}
.btn8h3:hover {
background: #496878;
}

/* Sochial */
.DS5f {
position: fixed;
bottom: 20px;
right: 20px;
background: #283946;
border-radius: 35px;
overflow: hidden;
width: auto;
z-index: 999;
}
.i6k {
font-size: 5vh;
margin-bottom: 5px;
}
.r6l2 {
transform: rotate(180deg);
}
.r6l3 {
width:500px;
}
.r6l4 {
width:50px;
}
.vsv{
max-width:0%;
}
.vbre {
right: 10px;
z-index: 99;
}

/* ABOUT */
.v7n3 {
font-family: 'Play', sans-serif;
overflow-y: auto;
height: calc(100% - 70px);
}
.h1p6 {
border-top: 3px #664f2c solid;
border-bottom: 3px #664f2c solid;
padding: 5px;
color: #ffbe5c;
}
.d6h4 {
max-width: 70%;
margin: 0 auto;
background: #191e206b;
padding: 20px;
}
.p2l6 {
margin:15px;
color: #f7ead5;
}
.p2g7  {
padding: 5px 10px 0 10px;
color: #f1e7d8;
border-top: none;
margin-top: 20px;
}
.p3h7 {
border: 2px #f1e7d8 dashed;
padding: 10px;
margin: 10px 20px;
color: #f1e7d8;
width:30%;
}
.a2l6 {
display: block;
color: #fb7979;
width: 78%;
margin: 0 auto;
margin-top:5px;
border: 2px #554343 dashed;
padding: 10px;
margin-bottom: 16px;
}
.a2l6:hover {
border-color:#7ef1ca;
color:#7ef1ca;
}
.n0g6 {
font-size: 5vh;
}
.n0g6 i{
color: #ffd59a;
}
.ul1 {
list-style-type: none;
width: 50%;
margin: 20px auto;
}
.ul1 li {
border: 1px #756d61 dashed;
padding: 5px;
margin: 5px;
color: #f1e7d8;
}
.h2l4 {
margin: 30px 10px 20px 10px;
color: #f3d9b1;
border-top: 2px #565046 dashed;
padding: 20px 0 0 0;
}
.dc74 {
padding: 20px;
margin: 20px 0;
border: 1px #c2b299 solid;
border-left: none;
border-right: none;
}
.s7v3 {
color: #fff500;
font-weight: 700;
}
.ltd4 {
border-top:1px #c2b299 solid;
padding: 20px;
}
.so1p {
background: #fff;
border-radius: 51%;
width: 50px;
height: 53px;
margin: 0 10px;
}
.so1p:hover {
background: #f5ff5d;
}
.m1o {
margin-top:-1px;
}

/* Airdrops */
.airit {
background: #1d3035b8;
min-height: 300px;
height:40vh;
margin: 20px;
}
.airl {
height: 100%;
padding: 8px;
max-width: 40vh;
min-width: 300px;
}
.whma1 {
max-height: 100%;
max-width: 100%;
top: 50%;
transform: translate(0, -50%);
}
.airr {
padding: 20px;
}
.airrow {
border-bottom: 1px #576f75 solid;
padding: 1px 0;
margin: 0 0 5px 0;
}
.btn9j3 {
color: #7d7d7d;
border: 1px #7e745f solid;
margin-top: 7px;
}
.brfd {
border: 1px #576f75 dashed;
width: 100%;
height: 100%;
}
.end {
font-size: 4vh;
color: #56666c;
margin-top: 5px;
padding-top: 10px;
border-top: 1px #56666c dashed;
min-height:10px;
width:100%;
}
.iis {
padding: 10px;
background: #283E43;
border-radius: 30px;
margin-left: 5px;
}
.iis:hover {
background: #3a6670;
}

.im8l5 {
height: 50%;
max-width: 60%;
margin: 0 auto;
margin-top: 25px;
}
.vr3h {
border-top: 1px #4d828b solid;
padding: 10px;
}
.a5g {
text-decoration: none;
display: inline-block;
}
.ap84 {
height: auto;
max-height: none;
}
.dv6 {
font-family: 'Play', sans-serif;
color: #f7ead5;
}
.dv6 h2 {
color:#ff9e00;
font-size:1.4rem;
padding:20px 0;
}
.dv6 h3 {
color:#fff200;
font-size:1.2rem;
padding:20px 0;
}
.dv6 a {
color: #25ff92;
}

.dv6 img {
margin: 5px 0;
}
.p2 {
border-top: 1px #2b4045 solid;
border-bottom: 1px #2b4045 solid;
padding: 10px 0;
margin: 20px 0;
}
.dQr {
font-family: 'Play', sans-serif;
position: relative;
z-index: 290;
overflow-y: auto;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.h3g {
padding: 20px 10px 0 10px;
color: #20ff00;
font-size: 1.2rem;
}
.qrt {
margin-bottom: 20px;
}
.p7j {
margin: 20px 0;
color: #ffb53c;
}
.p7j a {
color: #00e7ff;
}
.p5h {
padding-bottom:20px;
color: #ff6464;
font-size: 0.8rem;
}
.bbf2 {
  display: none;
}
.fml1 {
  display: none;
}
.rm0 {
  display: none;
}
.rm1 {
  display: none;
}
.ofa {
  overflow-y: auto;
}
.pofe3 {
  display: none;
}
.cjd5 {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  text-align:center;
  font-size:1.1rem;
  color:#ff8d8d;
}
.avvd4 {
  margin-top: 25px;
  color: #aaffc3;
}
@media screen and (max-width: 1050px) {
  .Dview {
    display: flex;
    flex-direction: column;
  }
  .d_inp {
    display: none;
  }
  .header {
    position: fixed;
    background: #152124;
    z-index: 1900;
    left: 0;
    padding: 0 10px;
    top: 0;
    height: 50px;
  }
  .dp0 {
    display: none;;
  }
  .rm0 {
    width: 33px;
    display: block;
  }
  .rm1 {
    width: 33px;
    display: block;
  }
  .mrl {
    position: relative;
    width: 100%;
    height: 3px;
    background: #fff;
    margin: 6px 0;
    opacity: 1;
    top: 0;
  } 
  .mrlz2 {
    width: 70% !important;
  } 
  .mrlz3 {
    width: 40% !important;
  } 
  .wr1 {
    padding: 0px 5px;
  }
  .bar1 {
    position: absolute;
    overflow-y: auto;
    left: -100%;
    position:fixed;
    z-index: 1000;
    background: #152124;
    width: 100%;
    padding: 0 15px;
    height: 100%;
    top: 50px;
  }
  .bar2 {
    width: 100%;
    padding: 0;
    text-align: center;
  }
  .bar22 {
    width: 100%;
    padding: 0;
    text-align: center;
  }
  .bar23 {
    width: 100%;
    padding: 0;
    text-align: center;
  }
  .page1 {
    display: block;
    margin-top: 55px;
  }
  .page12 {
    display: block;
    margin-top: 55px;
  }
  .page13 {
    display: block;
    margin-top: 55px;
  }
  .ditemNft {
    width: 100%;
    margin: 10px 0;
  }
  .n1Di  {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    width: 96%;
  }
  .nInf {
    width: 100%;
    border-right: none;
  }
  .nOff {
    width: 100%;
    border-top: 6px #475258 double;
    margin-top: 20px;
    padding: 0;
    padding-top: 15px;
  }
  .logo {
    height: auto;
    padding: 6px;
    width: 50px;
  }
  .logo img {
    height: auto;
  }
  .bbf2 {
  display: block;
  top: 4%;
  right: 8% !important;
  }
  .gg9 {
  display: none;
  }
  .lvv1 {
    left: 5% !important;
    width: 90% !important;
    z-index: 1800 !important;
    height: 90% !important;
  }
  .jkd4 {
    width: max-content;
  }
  .dNftNft {
    height: auto;
    margin: 12px 0;
    padding: 2px;
    width: 100%;
  }
  .arrow1 {
    width: 15px;
    margin: 6px;
  }
  .arrow1 i {
  font-size:15px;
  }
  .iNftNft {
    padding: 2px;
  }
  .DoffAct {
    margin: 0;
    margin-left: auto;
  }
  .d4g {
    z-index: 5000;
  }
  .ffv8 {
    display: none;
  }
  .box1 {
    width: 98%;
    height: calc(100% - 100px);
    overflow-y: auto;
    padding: 2px;
  }
  .btno1 {
    text-align: center !important;
    width: 30% !important;
    display: inline-block;
  }
  .add_ch {
    width: 100%;
    max-width: 100%;
    border: none;
    text-align: center;
  }
  .r_bxof {
    flex-direction: column;
  }
  .add_tab {
    width: 100%;
  }
  .lass1 {
    position: fixed;
    right: -100%;
    width: 100%;
    flex-direction: column;
    height: calc(100% - 50px);
    top: 49px;
    background: #152124;
    padding-top: 10%;
    overflow-y: auto;
    padding-left: 0;
  }

  .btnNavUp {
    margin: 15px 5px;
    font-size: 16px;
  }
  .whma1 {
    top: 0;
    transform: none;
  }
  .airit { 
    flex-direction: column;
    min-height: 0%;
    height: auto;
  }
  .d6h4 {
    max-width: 100%;
    padding: 5px;
  }
  .dc74 {
    width: 100%;
    padding: 20px 0;
  }
  .p2g7 {
    padding: 5px 0 5px 0;
  }
  .p2l6 {
    margin: 15px 0;
  }
  .a2l6 {
    width: 100%;
  }
  .ul1 {
    width: 100%;
  }
  .p3h7 {
    width: 100%;
  }
  .ver6p {
    flex-direction: column;
  }
  .v7n3 {
    height: calc(100% - 50px);
    top: 50px;
    position: relative;
  }
  .al54 {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 25px;
  }
  .pofe3 {
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .loc4 {
    padding-right: 6px;
    font-size: 14px;
    color: #a6f0d5;
  }
  .fgr4 {
    display: flex;
    border: 1px #a6f0d5 solid;
    padding: 5px 10px;
    align-items: center;
  }
  .so1p {
    width: 14%;
    max-width: 50px;
    height: auto;
    margin: 0 10px 10px 10px;
  }
  .fml1 {
    margin-top:20px;
    display: block;
  }
  .mrlA1 {
    transform: rotate(45deg);
    top: 9px;
  }
  .mrlA2 {
    opacity: 0;
  }
  .mrlA3 {
    transform: rotate(-45deg);
    top: -10px;
  }
}