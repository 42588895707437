.dv_wr {
width: calc(100vw - 210%);
max-width: 700px;
height:500px;
position: absolute;
z-index: 101;
top: 0;
background-color: #283e43f5;
border: 1px #324a50 solid;
overflow: hidden;
}

.dv-entering, .dv-entered {
opacity: 1;
left:110%;
}
.dv-exiting, .dv-exited {
opacity: 0;
left:0;
}